import { Link } from '@overdose/components'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import styles from './CategoryTile.module.css'
import { CategoryTileProps } from './CategoryTile.types'

const CategoryTile = ({
  title,
  image,
  action,
  blendBackground,
  size = 'md',
  isKeepWidth = false,
}: CategoryTileProps) => {
  return (
    <div className='relative w-min font-display-large-desktop-font-family text-primary-blue text-center ml-auto mr-auto'>
      <div
        className={classNames(
          styles.image,
          'relative rounded-full overflow-hidden w-16 aspect-square',
          {
            '4xl:w-24': size === 'lg',
          }
        )}>
        {image?.src && (
          <Image
            src={getImageSrc(image.src, '100')}
            addSrcSet={false}
            alt={image.altText}
            width={image.width}
            height={image.height}
            className={classNames(
              'h-full w-full',
              blendBackground ? 'mix-blend-multiply' : ''
            )}
            sizes='(max-width: 1920px) 64px, 128px'
          />
        )}
      </div>
      <div className='mt-2'>
        <Typography
          tag={TypographyTag.h2}
          variant={TypographyVariant.BodySmall}
          className={classNames('lg:!leading-4', styles.title, {
            'lg:!text-xs break-words': isKeepWidth,
            'lg:!text-sm': !isKeepWidth,
          })}>
          {title}
        </Typography>
      </div>
      {action && (
        <Link
          to={action.href}
          title={action.title || title}
          className='absolute top-0 left-0 w-full h-full z-10'
        />
      )}
    </div>
  )
}

export default CategoryTile
