import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

export interface ContentVidoeCarouselProps {
  title?: string
  source: {
    src: string
    type: string
  }[]
  width?: number
  autoplay?: boolean
  muted?: boolean
}

export const ContentVideoCarousel = ({
  title,
  source,
  width,
  ...attr
}: ContentVidoeCarouselProps) => {
  return (
    <div className='video-carousel'>
      <Swiper
        modules={[Navigation]}
        navigation={{
          enabled: true,
          disabledClass: '!pointer-events-auto opacity-80 !text-gray-400',
        }}
        className='max-w-full !ml-0'
        style={{ width }}>
        {source.map((video, index) => {
          return (
            <SwiperSlide key={index}>
              <iframe
                title={title || 'Embedded frame'}
                className='aspect-video w-full max-w-full !h-auto'
                src={video.src}
                {...attr}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
