/* eslint-disable camelcase */
import { useEffect, useMemo, useRef } from 'react'
import { Link, VisuallyHidden, useIsVisible } from '@overdose/components'
import classNames from 'classnames'
import { ProductImage } from '~/components/ProductImage'
import { PromoCardProps } from '~/components/PromoCard'
import RichText from '~/components/RichText/RichText'
import { SalePrice } from '~/components/SalePrice'
import { Stack } from '~/components/Stack'
import Tag from '~/components/Tag'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc, getImageSrcSet } from '~/helpers'
import { useAlgoliaSearchInsignts } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { useProductDataLayer } from '~/lib/gtm/hooks'
import styles from './PromoCard.module.css'

const richTextOptions = (color: string) => {
  return {
    customElementTypes: {
      p: (_attrs, children) => {
        return (
          <Typography
            variant={TypographyVariant.BodyRegular}
            tag={TypographyTag.p}
            className={classNames(color)}
            style={{ color }}>
            {children}
          </Typography>
        )
      },
    },
  }
}

export const PromoCard = ({
  action,
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  price,
  textColor,
  title,
  sku,
  description,
  tags,
  brandName,
  category1Name,
  category2Name,
  category3Name,
  category4Name,
  category5Name,
  listId,
  listName,
  item_status_tags,
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  productId,
  mobileBackgroundImage,
  wasText,
  saveText,
  className,
  richTextContent,
}: PromoCardProps) => {
  const isTextColorLight = textColor === 'light'
  const isTextColorDark = textColor === 'dark'
  const isImageCover = backgroundSize === 'cover'

  const textStyles =
    (isTextColorLight && '!text-white') ||
    (isTextColorDark && '!text-primary-heading')

  const { dispatchSelectPromotionDataLayerEvent } = useProductDataLayer()

  const product = useMemo(() => {
    const url = action?.href || ''
    const hasRequiredItemFields = sku || title
    if (!url.includes('/p/') || !hasRequiredItemFields) {
      return null
    }

    return {
      sku,
      title,
      finalPrice: price?.regular?.centAmount ? price.regular : price.final,
      brandName,
      category1Name,
      category2Name,
      category3Name,
      category4Name,
      category5Name,
      listId,
      listName,
      item_status_tags,
      index: /^\d+$/.test(creativeSlot) ? Number(creativeSlot) : 0,
    }
  }, [
    sku,
    title,
    price,
    brandName,
    category1Name,
    category2Name,
    category3Name,
    category4Name,
    category5Name,
    listId,
    listName,
    item_status_tags,
    action,
    creativeSlot,
  ])
  const isPromotion =
    !!promotionId && !!promotionName && !!creativeName && !!creativeSlot

  const ref = useRef()
  const isVisible = useIsVisible(ref)

  const { sendClickEvent } = useAlgoliaSearchInsignts()

  useEffect(() => {
    const payload = {
      promotionId,
      promotionName,
      creativeSlot,
      creativeName,
      product,
    }
    if (isVisible && isPromotion) {
      GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
      GTM.dispatch(GTMEvent.VIEW_PROMOTION, payload)
    }
  }, [
    isPromotion,
    isVisible,
    product,
    promotionId,
    promotionName,
    creativeName,
    creativeSlot,
  ])

  return (
    <div
      className={classNames(
        styles.root,
        'h-full w-full relative overflow-hidden max-lg:flex',
        className || ''
      )}
      style={{ backgroundColor }}
      ref={ref}>
      {(backgroundImage || mobileBackgroundImage?.src) && (
        <ProductImage
          title={title}
          src={getImageSrc(backgroundImage?.src)}
          addSrcSet={false}
          alt={backgroundImage.altText}
          width={backgroundImage.width}
          height={backgroundImage.height}
          sizes={`${backgroundImage?.width || Number('740')}px`}
          className={classNames(
            '!mix-blend-normal',
            'max-lg:order-2 max-lg:object-contain',
            isImageCover
              ? 'object-cover absolute inset-0 h-full w-full'
              : 'lg:object-scale-down object-contain absolute h-full w-full'
          )}
          style={{
            objectPosition: isImageCover
              ? '50% 50%'
              : `${backgroundPosition.x || 'center'} ${
                  backgroundPosition.y || 'center'
                }`,
          }}
          srcSet={getImageSrcSet(backgroundImage?.src, [
            {
              intrinsicImageSize: '400',
            },
            {
              intrinsicImageSize: '500',
            },
            {
              intrinsicImageSize: '600',
            },
          ])}
          sources={
            <source
              srcSet={getImageSrcSet(
                mobileBackgroundImage?.src || backgroundImage?.src,
                [
                  {
                    intrinsicImageSize: '400',
                  },
                  {
                    intrinsicImageSize: '500',
                  },
                  {
                    intrinsicImageSize: '600',
                  },
                ]
              )}
              media='(max-width: 768px)'
            />
          }
        />
      )}
      <Link
        to={action?.href || ''}
        className='absolute inset-0 z-20'
        data-id='viewItemButton'
        onClick={() => {
          isPromotion &&
            dispatchSelectPromotionDataLayerEvent({
              product,
              promotionId,
              promotionName,
              creativeSlot,
              creativeName,
            } as never)
          sendClickEvent([{ title, url: action?.href, listId: productId }])
        }}>
        <VisuallyHidden>{action.accessibleTitle}</VisuallyHidden>
      </Link>
      <div className='relative flex flex-col justify-between h-full z-10 p-4 md:p-6'>
        <div>
          {tags?.length > 0 && (
            <div className='mb-6'>
              <Stack direction='horizontal' spacing='xs'>
                {tags.map(
                  ({ variant, desc, backgroundColor, color }, index) => {
                    return (
                      <Tag
                        theme={{ tag: 'h-6' }}
                        key={index}
                        variant={variant}
                        backgroundColor={backgroundColor}
                        color={color}
                        children={
                          <span className='uppercase not-italic text-[10px]/[14px]'>
                            {desc}
                          </span>
                        }
                      />
                    )
                  }
                )}
              </Stack>
            </div>
          )}
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.Heading5}
            className={classNames(textStyles, styles.title, 'mb-6')}
            style={{ color: textColor }}>
            {title}
          </Typography>
          {description && (
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegular}
              className={classNames(textStyles, styles.title, 'pb-2')}
              style={{ color: textColor }}>
              {description}
            </Typography>
          )}
          {richTextContent && (
            <Typography
              tag={TypographyTag.div}
              className={classNames(title, 'pb-2')}
              variant={TypographyVariant.BodyRegular}>
              <RichText
                content={richTextContent}
                options={richTextOptions(textStyles || textColor)}
              />
            </Typography>
          )}
          {action && (
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegular}
              className={classNames(textStyles, styles.action, 'flex-1')}
              style={{ color: textColor }}>
              {action.title}
            </Typography>
          )}
        </div>
        {price && (
          <div>
            <SalePrice
              price={price}
              regularPriceClassName={classNames(
                textStyles,
                styles.regularPrice,
                'max-lg:text-xs'
              )}
              regularPriceTypographyVariant={TypographyVariant.BodyLarge}
              savePriceClassName={classNames(textStyles, 'max-lg:text-xs')}
              savePriceTypographyVariant={TypographyVariant.BodyLargeBold}
              finalPriceClassName={classNames(
                textStyles,
                '!font-body !font-bold !tracking-normal'
              )}
              finalPriceTypography={{
                variant: TypographyVariant.Heading4,
              }}
              wasText={wasText}
              saveText={saveText}
              displayAsButton={false}
              textColor={textColor}
            />
          </div>
        )}
      </div>
    </div>
  )
}
