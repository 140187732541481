/* eslint-disable camelcase */
import { useMemo } from 'react'
import { VisuallyHidden, Link } from '@overdose/components'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { ProductImage } from '~/components'

import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useAlgoliaSearchInsignts, useCompareProducts, usePage } from '~/hooks'
import { useProductDataLayer } from '~/lib/gtm/hooks'
import { Stack } from '../Stack'
import Tag from '../Tag'
import AddToCartButton from './AddToCartButton'
import AddToListButton from './AddToListButton'
import HelperText from './HelperText'
import styles from './ProductCard.module.css'
import { ProductCardProps } from './ProductCard.types'
import { ProductPrice } from './ProductPrice/ProductPrice'

const Checkbox = dynamic(
  async () => {
    const mod = await import('../Checkbox')
    return mod.Checkbox
  },
  {
    ssr: false,
  }
)

export const ProductCard = ({
  thumbnail,
  finalPrice,
  regularPrice,
  title,
  url,
  sku,
  showAddToCartButton = false,
  className,
  brandName,
  isFeatured = false,
  inStockText,
  availableForDelivery,
  showWishlistButton = true,
  reviewCount,
  rating,
  tag = '',
  index,
  category,
  category1Name,
  category2Name,
  category3Name,
  category4Name,
  category5Name,
  listId,
  listName,
  item_status_tags,
  imagePriority = false,
  inStock,
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  isShowCheck = true,
  noImage = false,
  tags = [],
  searchIndex = 0,
  inStore,
  objectId,
  queryId,
  externalUrl,
  isAvailableExternalLink = false,
  titleClass,
  isUpsell = false,
  showRating = true,
  showCompactAddToCart = true,
}: ProductCardProps) => {
  const { isProductInCompare, toggleProduct, canAddRemoveToCompare } =
    useCompareProducts()
  const {
    dispatchSelectItemDataLayerEvent,
    dispatchSelectPromotionDataLayerEvent,
  } = useProductDataLayer()
  const { sendClickEvent } = useAlgoliaSearchInsignts()
  const { brand } = usePage()
  const productUrl =
    !!externalUrl && isAvailableExternalLink ? externalUrl : url
  const product = useMemo(() => {
    return {
      thumbnail,
      finalPrice,
      regularPrice,
      title,
      url,
      sku,
      showAddToCartButton,
      className,
      brandName,
      isFeatured,
      inStockText,
      availableForDelivery,
      showWishlistButton,
      reviewCount,
      rating,
      tag,
      category1Name,
      category2Name,
      category3Name,
      category4Name,
      category5Name,
      listId,
      listName,
      item_status_tags,
      inStock,
      tags,
    }
  }, [
    thumbnail,
    finalPrice,
    regularPrice,
    title,
    url,
    sku,
    showAddToCartButton,
    className,
    brandName,
    isFeatured,
    inStockText,
    availableForDelivery,
    showWishlistButton,
    reviewCount,
    rating,
    tag,
    category1Name,
    category2Name,
    category3Name,
    category4Name,
    category5Name,
    listId,
    listName,
    item_status_tags,
    inStock,
    tags,
  ])
  const isPromotion =
    !!promotionId && !!promotionName && !!creativeName && !!creativeSlot

  const handleClick = () => {
    sendClickEvent(
      [{ title, url, listId, objectId }],
      !!searchIndex && [searchIndex],
      queryId
    )

    return isFeatured && isPromotion
      ? dispatchSelectPromotionDataLayerEvent({
          product,
          index,
          category,
          promotionId,
          promotionName,
          creativeName,
          creativeSlot,
        })
      : dispatchSelectItemDataLayerEvent(product, index, category)
  }

  return (
    <div
      className={classNames('flex flex-col justify-between h-full', className)}>
      {!noImage && (
        <div className='relative rounded-md p-4 bg-background-thumb product_image'>
          <div className='relative rounded-md overflow-hidden aspect-square'>
            {thumbnail?.altText && thumbnail?.src && (
              <ProductImage
                src={thumbnail?.src}
                alt={thumbnail?.altText}
                width={thumbnail?.width}
                height={thumbnail?.height}
                className='object-contain w-full h-full'
                sizes='(min-width: 1536px) 17vw, (min-width: 1366px) 230px, (min-width: 1024px) 260px, (min-width: 640px) 285px, 260px'
                priority={imagePriority}
              />
            )}
          </div>
          <div className='absolute left-2.5 top-2.5 pr-11'>
            <Stack direction='horizontal' spacing='xs'>
              {tags?.map((tag, index) => {
                return (
                  <Tag
                    variant='primary'
                    key={index}
                    backgroundColor={tag.backgroundColor}
                    color={tag.color}
                    theme={{
                      success: styles.tag,
                    }}>
                    <span className={classNames(styles.tag, 'uppercase')}>
                      {tag.desc}
                    </span>
                  </Tag>
                )
              })}
            </Stack>
          </div>

          {productUrl ? (
            <Link
              to={productUrl}
              title={`${title} details`}
              className='absolute inset-0'
              data-id='viewItemButton'
              onClick={handleClick}>
              <VisuallyHidden>{title} details</VisuallyHidden>
            </Link>
          ) : (
            <VisuallyHidden>{title} details</VisuallyHidden>
          )}
          {!isFeatured && isShowCheck && (
            <div className='absolute right-2.5 top-2.5'>
              <Checkbox
                label=''
                name={sku}
                size='lg'
                defaultChecked={false}
                disabled={!canAddRemoveToCompare(sku)}
                checked={isProductInCompare(sku)}
                onChange={() => {
                  toggleProduct(product)
                }}
                theme={{
                  box: 'm-0',
                }}
              />
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(
          'px-1.5 lg:px-4 !pb-4 h-full flex flex-col justify-between',
          isFeatured ? 'pt-4' : 'pt-2'
        )}>
        <div className='flex flex-col grow'>
          {brandName && (
            <Typography
              variant={TypographyVariant.BodyRegular}
              tag={TypographyTag.p}
              className={classNames(styles.brand, 'mb-1 h-4')}>
              {brandName}
            </Typography>
          )}
          {productUrl && (
            <Typography
              tag={TypographyTag.h3}
              variant={TypographyVariant.BodyLargeBold}
              className={classNames(
                'hover:underline lg:max-h-[72px]',
                styles.cardTitle,
                {
                  [styles.featuredCardTitle]: isFeatured,
                  'lg:max-h-[72px]': !isUpsell,
                },
                titleClass
              )}>
              <Link
                to={productUrl}
                variant='secondary'
                data-id='viewItemLink'
                title={`${title} details`}
                onClick={handleClick}
                className='md:line-clamp-3 overflow-wrap-anywhere'>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Link>
            </Typography>
          )}

          {sku && (
            <Typography
              variant={TypographyVariant.BodyRegular}
              tag={TypographyTag.p}
              className='text-greyscale-600 mt-4'>
              <span className='whitespace-nowrap font-bold'>CAT.NO: {sku}</span>
            </Typography>
          )}

          {showRating && !!rating && (
            <div className='mt-2 h-4'>
              <div
                className='rating_summary'
                data-bv-show='inline_rating'
                data-bv-product-id={sku}
              />
            </div>
          )}
        </div>

        <div>
          {(finalPrice || regularPrice) && (
            <ProductPrice
              isFeatured={isFeatured}
              finalPrice={finalPrice}
              regularPrice={regularPrice}
            />
          )}

          {sku && showAddToCartButton && (
            <div className='flex flex-wrap md:flex-nowrap items-center my-2 gap-x-2 gap-y-1 productCard-addToCart'>
              <AddToCartButton
                sku={sku}
                inStock={inStock}
                searchIndex={searchIndex}
                queryId={queryId}
                isButtonOnly={showCompactAddToCart}
                externalUrl={externalUrl}
              />
              <AddToListButton
                product={product}
                buttonProps={{
                  variant: 'ghost',
                  size: 'sm',
                  iconOnly: true,
                  className: '!w-[24px] !h-[40px] !min-w-[24px] !min-h-[40px]',
                }}
              />
            </div>
          )}
          {(availableForDelivery || inStock || inStockText) && (
            <HelperText
              availableForDelivery={availableForDelivery}
              inStockText={inStockText}
              brand={brand}
              inStore={inStore}
              inStock={inStock}
            />
          )}
        </div>
      </div>
    </div>
  )
}
