import { FC, useCallback, useEffect, useState } from 'react'
import { Button } from '@overdose/components'
import {
  IconAdjustmentsHorizontal,
  IconSearch,
  IconX,
} from '@tabler/icons-react'
import classNames from 'classnames'
import debounce from 'lodash-es/debounce'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FilterMapProps } from '~/components/FiltersDrawer'
import Input from '~/components/Input'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useAlgoliaSearchInsignts, useFormatPrice, useToast } from '~/hooks'
import {
  FilterProvider,
  useFilterContext,
} from '~/providers/FilterProvider/FilterProvider'
import { Pill } from '../Pill'
import styles from './ProductListFilters.module.css'
import {
  ProductListFiltersCoreProps,
  ProductListFiltersProps,
} from './ProductListFilters.types'

const FilterItem = dynamic(
  async () => {
    const mod = await import('./FilterItem')
    return mod.default
  },
  {
    loading: () => {
      return (
        <Pill
          title=''
          className={classNames(styles.filterLoader, 'md: w-24')}
        />
      )
    },
  }
)

const FiltersDrawer = dynamic(
  async () => {
    const mod = await import('~/components/FiltersDrawer')
    return mod.default
  },
  { ssr: false }
)

export const ProductListFilters: FC<ProductListFiltersCoreProps> = ({
  sort: sortOptions,
  search,
  title,
  searchPlaceholder = 'Search for an article',
  setPagination,
}) => {
  const router = useRouter()
  const [showFilterDrawer, setShowFilterDrawer] = useState(false)
  const [hasAllFilters, setHasAllFilters] = useState(false)
  const { showNotification, closeNotification } = useToast()
  const { sendFilterEvent } = useAlgoliaSearchInsignts()

  const {
    filters,
    selectedFilterMap,
    setSelectedFilterMap,
    selectedSortOption,
    setSelectedSortOption,
    searchKey,
    setSearchKey,
  } = useFilterContext()

  useEffect(() => {
    const otherFilters = { ...selectedFilterMap }
    delete otherFilters.q // Remove 'q' from the object

    const hasAnyFilter = Object.values(otherFilters).some((value) => {
      return value?.length > 0 && value[0] !== ''
    })
    setHasAllFilters(hasAnyFilter)
  }, [selectedFilterMap])

  const { formatPrice } = useFormatPrice()

  const handleShowFilterDrawer = useCallback(() => {
    setShowFilterDrawer(true)
  }, [setShowFilterDrawer])

  const handleHideFilterDrawer = useCallback(() => {
    setShowFilterDrawer(false)
  }, [setShowFilterDrawer])

  const updateFilterAndSort = useCallback(
    (selectedFilterMap, selectedSortOption?: string) => {
      if (!selectedFilterMap && !selectedSortOption) {
        return
      }

      const pathname = router?.asPath?.split('?')?.[0]
      const filterUrlParams = {}
      const initialMinPrice = filters
        ?.find((filter) => {
          return filter.uid === 'price'
        })
        ?.options.find((option) => {
          return option.uid === 'minPrice'
        })?.value
      const formattedMinPrice =
        typeof initialMinPrice === 'string'
          ? Number(initialMinPrice)
          : Number(formatPrice(initialMinPrice).slice(1))

      const minPrice =
        Number(selectedFilterMap.minPrice?.[0]) || formattedMinPrice
      for (const key in selectedFilterMap) {
        if (key !== 'sort' && selectedFilterMap[key]?.length > 0) {
          if (
            key === 'maxPrice' &&
            Number(selectedFilterMap.maxPrice?.[0]) < minPrice
          ) {
            showNotification({
              id: 'invalid-value',
              children: 'Max value should be greater than min value',
              type: 'error',
              onClose: closeNotification,
            })
            return
          }
          filterUrlParams[key] = selectedFilterMap[key]?.join('|')
        }
      }

      sendFilterEvent(selectedFilterMap, true)

      if (setPagination) {
        setPagination((prev) => {
          return {
            ...prev,
            currentPage: 1,
          }
        })
      }

      router.push(
        {
          pathname,
          query: {
            ...(searchKey ? { q: searchKey } : {}),
            ...filterUrlParams,
            ...(selectedSortOption ? { sort: selectedSortOption } : {}),
          },
        },
        undefined,
        { scroll: false }
      )
    },
    [
      router,
      filters,
      formatPrice,
      sendFilterEvent,
      setPagination,
      searchKey,
      showNotification,
      closeNotification,
    ]
  )

  const handleSortBy = useCallback(
    (_uid, value, checked) => {
      if (checked) {
        setSelectedSortOption(value)
        updateFilterAndSort(selectedFilterMap, value)
      }
    },
    [setSelectedSortOption, updateFilterAndSort, selectedFilterMap]
  )
  const handleFilter = useCallback(
    (uid: string, optionUid: string, checked: boolean) => {
      let updatedSelectedFilterMap = {}
      const currentOptions = selectedFilterMap[uid] || []
      const isPriceFilter = ['minPrice', 'maxPrice'].includes(uid)

      if (checked || (isPriceFilter && optionUid !== '')) {
        updatedSelectedFilterMap = {
          ...selectedFilterMap,
          [uid]: isPriceFilter ? [optionUid] : currentOptions.concat(optionUid),
        }
      } else {
        updatedSelectedFilterMap = {
          ...selectedFilterMap,

          [uid]: currentOptions.filter((el) => {
            return el !== optionUid
          }),
        }
      }

      if (updatedSelectedFilterMap[uid]?.length === 0) {
        delete updatedSelectedFilterMap[uid]
      }
      setSelectedFilterMap(updatedSelectedFilterMap)
      updateFilterAndSort(updatedSelectedFilterMap, selectedSortOption)
    },
    [
      selectedFilterMap,
      setSelectedFilterMap,
      selectedSortOption,
      updateFilterAndSort,
    ]
  )

  const SEARCH_KEYS_THREE = 3
  const DEBOUNCE_DELAY_HALF_SECOND = 500
  const DEBOUNCE_DELAY_TWO_SECONDS = 2000
  const DEBOUNCE_DELAY_200 = 200

  const handleSearch = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const hasRelevanceSort = sortOptions.some((option) => {
        return option.value === 'relevance'
      })

      if (
        hasRelevanceSort &&
        ((searchKey.length > 0 && e.key === 'Backspace') || e.key === 'Enter')
      ) {
        setSelectedSortOption('relevance')
      }

      if (e.key === 'Enter') {
        updateFilterAndSort(selectedFilterMap, selectedSortOption)
      } else {
        if (searchKey.length > SEARCH_KEYS_THREE) {
          debounce((selectedFilterMap, selectedSortOption) => {
            updateFilterAndSort(selectedFilterMap, selectedSortOption)
          }, DEBOUNCE_DELAY_HALF_SECOND)(selectedFilterMap, selectedSortOption)
        }

        if (searchKey.length > 0 && e.key === 'Backspace') {
          debounce((selectedFilterMap, selectedSortOption) => {
            updateFilterAndSort(selectedFilterMap, selectedSortOption)
          }, DEBOUNCE_DELAY_TWO_SECONDS)(selectedFilterMap, selectedSortOption)
        }

        if (searchKey.length === 0 && e.key === 'Backspace') {
          debounce((selectedFilterMap, selectedSortOption) => {
            updateFilterAndSort(selectedFilterMap, selectedSortOption)
          }, DEBOUNCE_DELAY_200)(selectedFilterMap, selectedSortOption)
          setSelectedSortOption(String(sortOptions[0].value))
        }
      }
    },
    [
      sortOptions,
      searchKey,
      setSelectedSortOption,
      updateFilterAndSort,
      selectedFilterMap,
      selectedSortOption,
    ]
  )

  const handleResetSelectedFilterMap = useCallback(
    (filterMap: FilterMapProps) => {
      setShowFilterDrawer(false)
      setSelectedFilterMap(filterMap)
      updateFilterAndSort(filterMap, selectedSortOption)
    },
    [selectedSortOption, setSelectedFilterMap, updateFilterAndSort]
  )

  return (
    <div
      className={`flex justify-between w-full lg:justify-${
        search ? 'between' : 'start'
      }`}>
      <div className='flex gap-x-2 xl:gap-x-4 flex-wrap gap-y-4 items-center'>
        {title && (
          <Typography
            tag={TypographyTag.span}
            className='hidden lg:block'
            variant={TypographyVariant.BodyLargeBold}>
            {title}
          </Typography>
        )}
        {sortOptions?.length > 0 && !search && (
          <>
            <FilterItem
              uid='sort'
              suffix='Sort By'
              selectionType='single'
              options={sortOptions}
              selected={{ sort: [selectedSortOption] }}
              onSelect={handleSortBy}
            />
            <div className={classNames(styles.divider, 'hidden lg:block')} />
          </>
        )}

        {filters?.map((filter, index) => {
          return filter.isFeatured ? (
            <div
              key={index}
              className={`${
                sortOptions?.length > 0 ? 'hidden' : 'block '
              } lg:block`}>
              <div key={index} className='hidden lg:block'>
                <FilterItem
                  {...filter}
                  selected={selectedFilterMap}
                  onSelect={handleFilter}
                />
              </div>
            </div>
          ) : null
        })}
        {!search && sortOptions?.length > 0 && (
          <Button
            status={hasAllFilters ? 'primary' : 'secondary'}
            shape='rounded'
            theme={{
              root: styles.filterBtn,
            }}
            icon={<IconAdjustmentsHorizontal />}
            iconAlignment='left'
            onClick={handleShowFilterDrawer}>
            <Typography
              tag={TypographyTag.span}
              variant={TypographyVariant.BodySmallBold}
              className={
                hasAllFilters
                  ? 'text-secondary-heading'
                  : 'text-primary-heading'
              }>
              All Filters
            </Typography>
          </Button>
        )}
        {hasAllFilters && (
          <Button
            status='secondary'
            shape='rounded'
            theme={{
              root: styles.filterBtn,
            }}
            icon={<IconX size={16} />}
            iconAlignment='left'
            onClick={() => {
              const currentFilter = {
                ...(selectedFilterMap.q && { q: selectedFilterMap.q }),
              }
              handleResetSelectedFilterMap(currentFilter)
            }}>
            <Typography
              tag={TypographyTag.span}
              variant={TypographyVariant.BodySmallBold}
              className='text-primary-heading'>
              Clear Filters
            </Typography>
          </Button>
        )}
      </div>
      {search && (
        <div className='flex flex-col lg:flex-row gap-y-4 gap-x-2 xl:gap-x-4 max-lg:w-full'>
          <div className='bg-white rounded-md'>
            <Input
              name='search'
              variant='default'
              placeholder={searchPlaceholder}
              prefix={<IconSearch size={20} className={styles.stroke2} />}
              value={searchKey}
              wrapperStyles={styles.searchInputFieldWrapper}
              onChange={setSearchKey}
              onKeyDown={handleSearch}
            />
          </div>
          <div className={classNames(styles.divider, 'hidden lg:block')} />
          <div className='flex items-center justify-between'>
            <div className='lg:hidden'>
              <Button
                status='secondary'
                shape='rounded'
                theme={{
                  root: styles.filterBtn,
                }}
                icon={<IconAdjustmentsHorizontal />}
                iconAlignment='left'
                onClick={handleShowFilterDrawer}>
                <Typography
                  tag={TypographyTag.span}
                  variant={TypographyVariant.BodySmallBold}
                  className='text-primary-heading'>
                  All Filters
                </Typography>
              </Button>
            </div>

            <FilterItem
              uid='sort'
              suffix='Sort By'
              selectionType='single'
              options={sortOptions}
              selected={{ sort: [selectedSortOption] }}
              onSelect={handleSortBy}
            />
          </div>
        </div>
      )}
      <FiltersDrawer
        open={showFilterDrawer}
        filters={filters}
        selected={selectedFilterMap}
        onClose={handleHideFilterDrawer}
        onChange={handleResetSelectedFilterMap}
      />
    </div>
  )
}

const ProductListFiltersWrapper: FC<ProductListFiltersProps> = (props) => {
  const { selectedFilters, filters, selectedSort, ..._props } = props

  return (
    <FilterProvider
      filters={filters}
      selectedFilters={selectedFilters}
      selectedSort={selectedSort}>
      <ProductListFilters {..._props} />
    </FilterProvider>
  )
}

export default ProductListFiltersWrapper
