import { KeyboardEvent, useId } from 'react'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import classNames from 'classnames'

import { Button } from '~/components/Button'
import { CartQuantityInputProps } from '~/components/CartQuantityInput'
import Input from '~/components/Input'
import Squircle from '../QuantitySelector/Squircle'
import styles from './CartQuantityInput.module.scss'

const STEP = 1
const MIN_VALUE = 0

const CartQuantityInput = ({
  onChange,
  maxValue,
  className,
  id,
  isDisabled,
  value,
  size = 'default',
}: CartQuantityInputProps) => {
  const inputId = useId()
  const canDecrement = MIN_VALUE == null || value > MIN_VALUE
  const canIncrement = maxValue == null || value < maxValue

  const handleButtonClick = (step: number, event?: MouseEvent) => {
    event?.stopPropagation()
    event?.preventDefault()

    if ((step < 0 && !canDecrement) || (step > 0 && !canIncrement)) {
      return
    }

    const newValue = value + step
    onChange?.(newValue)
  }

  const handleInputChange = (value: string) => {
    if (/^\s*$/.test(value)) {
      value = '0'
    } else if (!/^\d+$/.test(value)) {
      return
    }

    const newValue = Number(value)
    onChange?.(newValue)
  }

  const handleKeyDown = (event?: KeyboardEvent<HTMLInputElement>) => {
    const key = event.key

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      event?.stopPropagation()
      event?.preventDefault()

      handleButtonClick(key === 'ArrowDown' ? -STEP : STEP)
    }
  }

  return (
    <div
      id={id}
      className={classNames(
        styles.root,
        styles[size],
        className,
        'flex items-center',
        {
          '!cursor-not-allowed': isDisabled,
        }
      )}>
      <Button
        variant='ghost'
        className={styles.controlButton}
        disabled={!canDecrement || isDisabled}
        onClick={(e: MouseEvent) => {
          handleButtonClick(-STEP, e)
        }}>
        <Squircle>
          <IconMinus color='white' className='!w-2/3 !h-2/3' />
        </Squircle>
      </Button>
      <Input
        name={id ?? inputId}
        wrapperStyles='flex-grow'
        theme={{ input: styles.input }}
        defaultValue='0'
        value={value?.toString()}
        onChange={(value) => {
          handleInputChange(value)
        }}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          handleKeyDown(event)
        }}
        disabled={isDisabled}
      />
      <Button
        variant='ghost'
        className={styles.controlButton}
        disabled={!canIncrement || isDisabled}
        onClick={(event: MouseEvent) => {
          handleButtonClick(STEP, event)
        }}>
        <Squircle>
          <IconPlus color='white' className='!w-2/3 !h-2/3' />
        </Squircle>
      </Button>
    </div>
  )
}

export default CartQuantityInput
