import { useMemo } from 'react'
import { Link } from '@overdose/components'
import classNames from 'classnames'
import RichText from '../RichText/RichText'
import styles from './SitewideBanner.module.css'
import { SitewideBannerProps } from './SitewideBanner.types'

const SitewideBanner = ({
  backgroundColor,
  content,
  linkUrl,
  openInNewTab,
  textColor,
}: SitewideBannerProps) => {
  const contentStyle = useMemo(() => {
    return {
      '--color-typography-heading': textColor,
      '--color-secondary-muted': textColor,
      '--color-text-primary': textColor,
      '--section-title-link-color': textColor,
      '--sitewide-banner-background-color': backgroundColor,
    } as React.CSSProperties
  }, [textColor, backgroundColor])

  const renderContent = () => {
    const textContent = <RichText content={content} />

    if (!linkUrl) {
      return textContent
    }

    const target = openInNewTab ? '_blank' : null

    return (
      <Link className='block' to={linkUrl} target={target}>
        {textContent}
      </Link>
    )
  }

  return (
    <div
      className={classNames(
        styles.sitewideBanner,
        'sitewideBanner',
        'flex box-border items-center justify-center px-2'
      )}
      style={contentStyle}>
      {renderContent()}
    </div>
  )
}

export default SitewideBanner
