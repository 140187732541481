import { Link } from '@overdose/components'
import { Image } from '~/types'
import { NEW_TAB_LINK_TARGET } from '../../constants/links'
import { ImageOverlayLinksProps } from './ImageOverlayLinks.types'

const ImageOverlayLinks = ({
  desktopImage,
  tabletImage,
  mobileImage,
}: ImageOverlayLinksProps) => {
  const renderImageLink = (image: Image, className: string) => {
    if (!image?.linkUrl) {
      return null
    }

    return (
      <Link
        className={className}
        to={image?.linkUrl}
        target={image?.openLinkInNewTab ? NEW_TAB_LINK_TARGET : null}
      />
    )
  }

  const generateDesktopLinkClass = () => {
    if (!tabletImage?.src && !mobileImage?.src) {
      return 'block absolute z-20 inset-0'
    }

    if (tabletImage?.src) {
      return 'hidden min-[1025px]:block absolute z-20 inset-0'
    }

    return 'hidden min-[769px]:block absolute z-20 inset-0'
  }

  const generateTabletLinkClass = () => {
    if (!mobileImage?.src) {
      return 'block min-[1025px]:hidden absolute z-20 inset-0'
    }

    return 'hidden min-[769px]:max-[1024px]:block absolute z-20 inset-0'
  }

  return (
    <>
      {renderImageLink(desktopImage, generateDesktopLinkClass())}
      {renderImageLink(tabletImage, generateTabletLinkClass())}
      {renderImageLink(
        mobileImage,
        'block min-[769px]:hidden absolute z-20 inset-0'
      )}
    </>
  )
}

export default ImageOverlayLinks
